<template>
    <div class="page-content white-bg">
        <NewsGuide :typeId="typeId" :id="id" />
    </div>
</template>

<script>
export default {
    name: "home",
    data() {
        return {
            typeId: "10000055",
            id: this.$route.query.id || "",
        };
    },
    methods: {},
};
</script>
<style lang="less" scoped>
</style>
